
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Prop} from 'vue-property-decorator';

    import {
        IAccount,
        IAccountRole,
        IInvitation,
        IOrganization,
        IPlaceSuggestion,
    } from '@/types';

    import {addressHelper, formHelper, stripeHelper} from '@/helpers';

    import IbanInput from '@/components/forms/IbanInput.vue';
    import StripeCard from '@/components/forms/StripeCard.vue';
    import PlaceInput from '@/components/forms/PlaceInput.vue';
    import {localStorageService} from '@/storage/localstorage.service';

    import {OrganizationModule, SnackbarModule} from '@/store/modules';

    const accountNamespace = namespace('account');
    const registrationNamespace = namespace('registration');

    @Component<CreateSubscription>({
        components: {
            IbanInput,
            StripeCard,
            PlaceInput,
        },
    })
    export default class CreateSubscription extends Vue {
        public stripeIban: stripe.elements.Element|null = null;
        public stripeCard: stripe.elements.Element|null = null;
        public clientSecretStripe: string = '';
        public paymentForm: boolean = false;
        public paymentOwnerName: string|null = null;
        public paymentOwnerEmail: string|null = null;
        public paymentOwnerUseOrganizationAddress: boolean = true;
        public paymentOwnerUseUserEmail: boolean = true;
        public paymentOwnerAddress: IPlaceSuggestion|null = null;
        public paymentCoupon: string|null = null;
        public paymentMethod: string = '';
        public cardErrorMessage: string = '';
        public subscriptionType: string = 'payperuse';
        public paymentCouponInvalid: boolean = false;
        public ownerNameRules = formHelper.getOwnerNameRules();
        public ownerEmailRules = formHelper.getOwnerEmailRules();
        public ownerAddressRules = formHelper.getOwnerAddressRules();
        public confimElmStripe: boolean = false;
        public errorMessage: string = '';
        public submittingStripe: boolean = false;
        public displayShadow: boolean = false;
        public sendingCreateSubscriptionRequest: boolean = false;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount|null;

        @accountNamespace.Getter('loggedAccountRoles')
        public loggedAccountRoles!: IAccountRole[];

        @registrationNamespace.Getter('invitation')
        public invitation!: IInvitation|null;

        @Prop({
            type: String,
            required: false,
        })
        public organizationId!: string;

        private stripeModule = Stripe(process.env.VUE_APP_STRIPE_KEY as string);

        get organization() {
            const role = this.loggedAccountRoles.find((val: IAccountRole) => {
                return val.organization && val.organization.id === this.organizationId;
            });

            return role ? role.organization : null;
        }

        get billingAddress() {
            if (this.paymentOwnerUseOrganizationAddress) {
                return {
                    id: (this.organization as IOrganization).location.external_id,
                    formatted: addressHelper.formatForStripe((this.organization as IOrganization).address),
                };
            }

            const parsedPlace = addressHelper.parseRawData(this.paymentOwnerAddress as IPlaceSuggestion);

            return {
                id: (this.paymentOwnerAddress as IPlaceSuggestion).raw_data.place_id,
                formatted: addressHelper.formatForStripe(parsedPlace),
            };
        }

        public sendCreateSubscriptionRequest() {
            return new Promise((resolve, reject) => {
                const registrationForm = localStorageService.loadObject('registration_data');
                if(this.clientSecretStripe) {
                    let owner = {};
                    if (this.paymentMethod === 'sepa_debit') {
                        owner = {
                            address: this.billingAddress.formatted,
                            name: this.paymentOwnerName ? this.paymentOwnerName as string : registrationForm.lastName,
                            email: this.paymentOwnerEmail ? this.paymentOwnerEmail : this.loggedAccount?.email,
                        };
                    }
                    const stripeElm = this.paymentMethod === 'sepa_debit' ?  this.stripeIban :  this.stripeCard;
                    resolve(stripeHelper.confirmStripeCardPayement(this.clientSecretStripe, this.stripeModule, stripeElm as any, this.paymentMethod, owner))
                }
            })
        }

        public onEventChange(event: any) {
            this.confimElmStripe = event.complete;
            this.errorMessage = event.error ? event.error.message : '';
        }

        
        public setupIntentStripe() {
            const registrationForm = localStorageService.loadObject('registration_data');
            this.confimElmStripe=false;
            this.errorMessage='';
            this.displayShadow = true;

            const subscriptionParams = {
                email: this.paymentOwnerEmail ? this.paymentOwnerEmail : this.loggedAccount?.email as string,
                owner_name: this.paymentOwnerName ? this.paymentOwnerName as string : registrationForm.lastName,
                place_id: this.billingAddress.id!,
                price_id: this.subscriptionType === 'subscription' ? process.env.VUE_APP_STRIPE_PRICE_ID : process.env.VUE_APP_STRIPE_PRICE_ID,
            }

            getModule(OrganizationModule, this.$store)
                .createSetupIntent({
                    organizationId: (this.organization as IOrganization).id,
                    subscription: subscriptionParams,
                }).then((data)=> {
                    if(data.secret)
                    this.clientSecretStripe = data.secret;
                    const snackbarModule = getModule(SnackbarModule, this.$store);

                    this.sendCreateSubscriptionRequest()
                    .then(()=> {
                        snackbarModule.displaySuccess(
                            `Votre souscription a bien été enregistrée !<br>
                            Vous pouvez désormais profiter pleinement de Vetolib !`,
                        );
                        this.displayShadow = false;
                        this.$emit('subscription-created');
                    })
                    .catch((error) => {
                            if (error.response.data.error === 'invalid_promotion_code') {
                                snackbarModule.displayError('Le code saisi est invalide');
                                this.paymentCouponInvalid = true;
                            } else {
                                snackbarModule.displayError();
                            }
                        })
                    .finally(() => this.sendingCreateSubscriptionRequest = false);
                })
        }

        public mounted() {
            if (this.invitation?.promotion_code) {
                this.paymentCoupon = this.invitation.promotion_code;
            }
        }
    }
