
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Emit, Watch, Prop} from 'vue-property-decorator';
    import {debounce} from 'debounce';

    import {
        IPlaceSuggestion,
        IOrganization,
    } from '@/types';

    @Component<OrganizationAutocomplete>({})
    export default class OrganizationAutocomplete extends Vue {
        public isLoading: boolean = false;
        public items: IOrganization[] = [];
        public searchText: string|null = null;
        public innerModel: string|null = null;

        @Prop({
            type: Boolean,
            required: false,
            default: false,
        })
        public fromTunnel!: boolean;

        @Prop({
            type: Object,
            required: false,
        })
        public location!: IPlaceSuggestion;

        @Prop({
            type: Boolean,
            required: false,
            default: false,
        })
        public isClient!: boolean;

        private debouncedAutocompleteQuery = debounce(this.sendAutocompleteQuery, 300);

        @Watch('searchText')
        private onSearchTextChange(val: string) {
            if (!val) {
                this.items = [];
                return;
            }
            this.$emit('search-text', this.searchText);

            this.debouncedAutocompleteQuery(val);
        }

        @Emit('input')
        private onValueChange() {
            return this.innerModel;
        }

        private sendAutocompleteQuery(val: string) {
            if (!this.location || !this.location.position) {
                return;
            }

            this.isLoading = true;

            return this.$api.organization
                .search({
                    name: val,
                    latitude: this.location.position.latitude,
                    longitude: this.location.position.longitude,
                    client: this.isClient,
                    sort_by: 'pro',
                })
                .then((suggestions: IOrganization[]) => {
                    this.items = suggestions;
                })
                .finally(() => this.isLoading = false)
            ;
        }
    }
